import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Table,
  TableBody,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Box
} from '@mui/material';
import DetailRow from 'src/components/DetailRow';
import { useDispatch, useSelector } from 'react-redux';
import {
  LOAD_CLIENT_BANK_ACCOUNTS,
  loadClientBankAccountInformation,
  updateClientBankAccountInformation
} from 'src/store/actions/clientActions';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Button from 'src/components/Button';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { selectClientBankAccount } from 'src/store/selectors/clientSelectors';
import Loading from 'src/components/Loading';
import { BankAccountType } from 'src/utils/constants/clients';
import useStyles from '../styles';
import EditBankAccountDetailDialog from './EditBankAccountDetailDialog';

const BankAccountDetail = ({ client }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();

  const bankAccountDetail = useSelector(selectClientBankAccount);

  const [openEditDialog, setOpenEditDialog] = useState(false);

  const handleOpenEdit = () => {
    setOpenEditDialog(true);
  };

  const handleCloseEdit = () => {
    setOpenEditDialog(false);
  };

  const handleSubmit = useCallback(
    values => {
      dispatch(updateClientBankAccountInformation(id, values, handleCloseEdit));
    },
    [client, dispatch]
  );
  const loading = useSelector(
    isLoadingSelector([LOAD_CLIENT_BANK_ACCOUNTS.action])
  );

  const translateBankAccountType = useCallback(
    type => {
      switch (type) {
        case BankAccountType.SANTANDER_CHECKS:
          return t('ENUMS.ACCOUNT_TYPE.SANTANDER_CHECKS');
        case BankAccountType.DEBIT_CARD:
          return t('ENUMS.ACCOUNT_TYPE.DEBIT_CARD');
        case BankAccountType.CLABE:
          return t('ENUMS.ACCOUNT_TYPE.CLABE');
        default:
          return '';
      }
    },
    [t]
  );

  useEffect(() => {
    dispatch(loadClientBankAccountInformation(id));
  }, [client]);

  return (
    <Card className={classes.tableContainer}>
      <CardHeader
        className={classes.header}
        title={
          <Typography className={classes.tableCell} component="h4" variant="h4">
            {t('CLIENTS_VIEW.BILLING_DETAIL.ACCOUNT_INFORMATION')}
          </Typography>
        }
      />
      <Divider className={classes.tableCell} />
      <CardContent sx={{ padding: 0 }}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Table>
              <TableBody>
                <DetailRow
                  name={t('CLIENTS_VIEW.BILLING_DETAIL.INTEGRATED_BANK')}
                  value={t('CLIENTS_VIEW.BILLING_DETAIL.SANTANDER_BANK')}
                />
                <DetailRow
                  name={t('CLIENTS_VIEW.BILLING_DETAIL.BANK')}
                  value={bankAccountDetail?.bankName || t('COMMON.NO_DATA')}
                />
                <DetailRow
                  name={t('CLIENTS_VIEW.BILLING_DETAIL.BANK_ACCOUNT_TYPE')}
                  value={
                    translateBankAccountType(
                      bankAccountDetail?.bankAccountType
                    ) || t('COMMON.NO_DATA')
                  }
                />
                <DetailRow
                  name={t('CLIENTS_VIEW.BILLING_DETAIL.ACCOUNT_NUMBER')}
                  value={bankAccountDetail?.bankAccount || t('COMMON.NO_DATA')}
                />
              </TableBody>
            </Table>
            <Box sx={{ width: '90%', margin: 'auto' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenEdit}
              >
                {t('COMMON.EDIT')}
              </Button>

              <EditBankAccountDetailDialog
                open={openEditDialog}
                close={handleCloseEdit}
                submit={handleSubmit}
                bankAccountData={bankAccountDetail}
              />
            </Box>
          </>
        )}
      </CardContent>
    </Card>
  );
};

BankAccountDetail.propTypes = {
  client: PropTypes.object.isRequired
};

export default BankAccountDetail;
