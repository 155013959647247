import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TextField,
  IconButton,
  FormControl
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getDifferenceBetweenStates } from 'src/utils/common';
import { useTranslation } from 'react-i18next';
import DialogTransition from 'src/components/DialogTransition';
import { clearCatalog, loadBankList } from 'src/store/actions/catalogActions';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import NumberMask from 'src/components/inputMask/NumberMask';
import DropdownMenu from 'src/components/DropdownMenu';
import {
  BankAccountType,
  SantanderBankCode
} from 'src/utils/constants/clients';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { UPDATE_CLIENT_BANK_ACCOUNTS } from 'src/store/actions/clientActions';
import Loading from 'src/components/Loading';
import makeInitialState from './initialState';
import validationSchema from './schema';
import useStyles from '../../../styles';

const AccontTypeOptions = [
  {
    name: 'ENUMS.ACCOUNT_TYPE.SANTANDER_CHECKS',
    value: BankAccountType.SANTANDER_CHECKS
  },
  { name: 'ENUMS.ACCOUNT_TYPE.DEBIT_CARD', value: BankAccountType.DEBIT_CARD },
  { name: 'ENUMS.ACCOUNT_TYPE.CLABE', value: BankAccountType.CLABE }
];

const EditBankAccountDetailDialog = ({
  open,
  close,
  submit,
  bankAccountData
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const initialState = makeInitialState(bankAccountData);
  const isLoading = useSelector(
    isLoadingSelector([UPDATE_CLIENT_BANK_ACCOUNTS.action])
  );
  const [bankList, setBankList] = useState([]);
  const resetRef = useRef();
  const { t } = useTranslation();

  const handleSubmitBankAccountData = useCallback(
    values => {
      const difference = getDifferenceBetweenStates(initialState, values);
      if (Object.entries(difference).length === 0) {
        close();
        return;
      }
      submit({
        ...difference
      });
    },
    [submit, close, initialState]
  );

  useEffect(() => {
    if (open) {
      dispatch(loadBankList(setBankList));
    }
    return () => {
      dispatch(clearCatalog());
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  if (!open) {
    return null;
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={DialogTransition}
      keepMounted
    >
      <DialogTitle className={classes.header} id="form-general-dialog-title">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            close();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {t('CLIENTS_VIEW.BILLING_DETAIL.EDIT_BANK_ACCOUNT_DATA')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Loading />
        ) : (
          <Formik
            enableReinitialize
            initialValues={initialState}
            validationSchema={validationSchema}
            onSubmit={handleSubmitBankAccountData}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              resetForm,
              setFieldValue
            }) => {
              resetRef.current = () => resetForm();
              return (
                <form onSubmit={handleSubmit}>
                  <FormControl
                    fullWidth
                    clearIcon={null}
                    onChange={(_e, value) => {
                      setFieldValue('bankName', value ? value.name : '');
                      setFieldValue('bankCode', value ? value.code : '');
                    }}
                    helperText={touched.bankName && t(errors.bankName)}
                    error={Boolean(touched.bankName && errors.bankName)}
                  >
                    <Autocomplete
                      label={t('COMMON.BANK')}
                      name="bankName"
                      options={bankList}
                      id="bankName"
                      value={
                        bankList.find(bank => bank.name === values.bankName) ??
                        ''
                      }
                      getOptionLabel={option => {
                        return option.name ? `${option.name}` : '';
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.name === value.name
                      }
                      fullWidth
                      clearIcon={null}
                      onChange={(_e, value) => {
                        setFieldValue('bankName', value ? value.name : '');
                        setFieldValue('bankCode', value ? value.code : '');

                        if (value.code === SantanderBankCode) {
                          setFieldValue(
                            'bankAccountType',
                            BankAccountType.SANTANDER_CHECKS
                          );
                        } else {
                          setFieldValue(
                            'bankAccountType',
                            BankAccountType.CLABE
                          );
                        }
                      }}
                      helperText={touched.bankName && t(errors.bankName)}
                      error={Boolean(touched.bankName && errors.bankName)}
                      sx={{ marginTop: 2 }}
                    />
                  </FormControl>

                  <DropdownMenu
                    list={AccontTypeOptions.map(item => ({
                      name: `${t(item.name)}`,
                      key: item.value
                    }))}
                    value={values.bankAccountType}
                    setValue={value => {
                      setFieldValue('bankAccountType', value);
                    }}
                    label={t('COMMON.ACCOUNT_TYPE')}
                    disabled
                  />

                  <TextField
                    InputProps={{
                      autoComplete: 'off',
                      inputComponent: NumberMask
                    }}
                    error={Boolean(touched.bankAccount && errors.bankAccount)}
                    fullWidth
                    helperText={touched.bankAccount && t(errors.bankAccount)}
                    label={t('CLIENTS_VIEW.BILLING_DETAIL.ACCOUNT_NUMBER')}
                    margin="normal"
                    name="bankAccount"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.bankAccount}
                    variant="outlined"
                  />

                  <Grid container direction="column" alignItems="stretch">
                    <Grid item className={classes.buttons}>
                      <Button
                        className={clsx(classes.label, classes.buttons)}
                        type="submit"
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.SAVE')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        )}
      </DialogContent>
    </Dialog>
  );
};

EditBankAccountDetailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  bankAccountData: PropTypes.object
};

export default EditBankAccountDetailDialog;
